<template>
  <b-modal
    id="delete-configuration-modal"
    title="Delete Configuration. Are you absolutely sure?"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Yes, delete configuration'"
    :ok-disabled="loading"
    ok-variant="danger"
    cancel-title="Keep"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @ok.prevent="onSubmit"
  >
    <b-alert
      show
      variant="danger"
    >
      <div class="alert-body d-inline-flex justify-content-between">
        <div class="mr-1">
          <feather-icon
            icon="AlertCircleIcon"
            class="text-white"
            size="30"
          />
        </div>
        <div>
          <p>
            You're about to delete configuration
            <span class="font-weight-bolder text-primary">{{ configObj.name }}</span>
            and
            <span class="font-weight-bolder text-primary">all its links</span> permanently.
          </p>
          <p>
            To avoid unintended data loss, consider reviewing this action before deleting it.
            The linked entities will be unaffected.
          </p>
        </div>
      </div>
    </b-alert>
    <div>
      <b-table
        responsive="sm"
        :fields="tableFields"
        :items="tableData"
        hover
      />
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { computed, ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import store from '@/store'
import { BAlert, BTable } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DeleteConfigurationModal',
  components: {
    BAlert,
  },
  props: {
    configObj: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const tableFields = [
      {
        key: 'property', label: 'Property', thStyle: 'width: 10rem;', tdClass: 'font-small-3 text-muted',
      },
      { key: 'value', label: 'Value', tdClass: 'font-small-3' },
    ]

    const tableData = computed(() => [
      { property: 'ID', value: props.configObj.id },
      { property: 'Name', value: props.configObj.name },
      { property: 'Description', value: props.configObj.description },
      { property: 'Components', value: props.configObj.components.length },
      { property: 'Interfaces', value: props.configObj.interfaces.length },
      { property: 'Instances', value: props.configObj.instances.length },
      { property: 'Tests', value: props.configObj.tests.length },
      {
        property: 'Created By',
        value: `${context.root.getUserUsername(props.configObj.created_by)} at ${props.configObj.created}`,
      },
    ])

    const onSubmit = () => {
      loading.value = true
      coreService.delete(`/v1/legacy/domain_model/build_configuration/${props.configObj.id}`)
        .then(() => {
          context.emit('configuration-deleted')
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Configuration Deleted',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
          context.root.$bvModal.hide('delete-configuration-modal')
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete Configuration',
              text: `${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }

    return {
      loading,
      tableFields,
      tableData,
      onSubmit,
    }
  },
}
</script>
